import axiosInstance from "../axios";
import { encryptRequest } from "@/utils/encrypt";
/**
 * 获取图片验证码
 * @returns 
 */
export const getPicVerify = () => axiosInstance.post('/interface/common/getVerify')

/**
 * 获取短信验证码
 */
export const sendSms = (data) => encryptRequest({ url: '/interface/common/sendSms', method: 'post', data })

/**
 * 检查当前token的登陆态
 * @returns 
 */
export const checkLogin = () => axiosInstance.post('/interface/customer/checkLogin')

/**
 * 登录接口
 */
export const login = (data) => encryptRequest({ url: '/interface/customer/login', method: 'post', data })

/**
 * 退出登录
 */
export const signOut = () => axiosInstance.post('/interface/customer/signOut');