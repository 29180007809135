import axiosInstance from "../axios";

/**
 * 融资资讯列表
 */
export const newsListData = (data) => axiosInstance.post("/interface/new/listData", data);

/**
 * 融资资讯详情
 */
export const newsDetail = (id) => axiosInstance.get(`/interface/new/data/${id}`);
