import axiosInstance from '@/services/axios'

const publicKey = '042c3447df22622e79a51e5da98c6fded0b43d78495e3d3bc9a2a88887a077ffaab67cdf8c27dcff2bb62026244fa89fe403e8a39ddc7481e68296532fa3187e23'
const privateKey = '8a7d30c2de2c6bc7d0899fd629a75f8691b6fd8ab90a3b84a3c4238538bfc360'
const sm2 = require('sm-crypto').sm2
const cipherMode = 1 // 1 - C1C3C2，0 - C1C2C3，默认为1

const encrypt = (source) => {
  let encryptData = sm2.doEncrypt(source, publicKey)
  return encryptData
}

export const encryptDataFn = (source) => JSON.parse(sm2.doDecrypt(source, privateKey, cipherMode))


const JqueryCryptRequest = (options) => {
  let { data, success, type, parmas, resultPlaintext } = options
  if (type.toLocaleUpperCase() === 'GET') { return new Error('GET方法不支持加密') }
  data = data ? JSON.stringify({ data: encrypt(data) }) : data
  $.ajax({
    ...options,
    data: parmas ? parmas : data,
    success (res) {
      if (!resultPlaintext && res.data) {
        res.data = JSON.parse(sm2.doDecrypt(res.data.data, privateKey, cipherMode))
      }
      success && success(res)
    }
  })
}
const AxiosCryptRequest = async (options) => {
  let { data, method, plaintext, resultPlaintext } = options
  if (method.toLocaleUpperCase() === 'GET') { return new Error('GET方法不支持加密') }
  const encryptData = data ? { data: encrypt(JSON.stringify(data)) } : data
  try {
    const res = await axiosInstance({
      ...options,
      headers: {
        'Content-Type': 'application/json'
      },
      data: plaintext ? data : encryptData,

    })
    if (!resultPlaintext && res) {
      return JSON.parse(sm2.doDecrypt(res.data, privateKey, cipherMode))
    } else {
      return res
    }
  } catch (error) {
    return Promise.reject(error)
  }

}
export const encryptRequest = (options, type = 'axios') => {
  if (type === 'jquery') {
    return JqueryCryptRequest(options)
  } else {
    return AxiosCryptRequest(options)
  }
}