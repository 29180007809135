import { encryptRequest } from "@/utils/encrypt"
import axiosInstance from "../axios";

/**
 * 融资需求，推荐产品
 */
export const getRecommendedProducts = (data) => axiosInstance.post("/interface/product/finance-recommended", data);

/**
 * 指定银行
 */
export const designatedBank = (data) => axiosInstance.post("/interface/comfinneeds/designatedBank", data);

 /**
 * 融资需求提交
 */
export const saveDemandRelease = (data) => encryptRequest({url:"/interface/comfinneeds/save",method:'post', data});

 /**
 * 我的企业融资需求
 */
export const financeNeeds = (data) => encryptRequest({ url: "/interface/customer/financeNeeds", method: 'post', data , plaintext:true });
