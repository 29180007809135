import { encryptRequest } from "@/utils/encrypt"
import axiosInstance from "../axios";

/**
 * 获取申请按钮状态
 */
export const getApplyBtnStatus = () => axiosInstance.post('/interface/hqd/server-time');

/**
 * 企业名模糊匹配
 */
export const getEnterpriseList = (data) => axiosInstance.post('/interface/hqd/enterprise-list', data);

/**
 * 查询意向银行
 */
export const getIntentionalBank = (data = {}) => axiosInstance.post('/interface/hqd/intentional-bank', data);

/**
 * 查询企业信息
 */
export const getEnterpriseInfo = (data = {}) => encryptRequest({ url: '/interface/hqd/enterprise-info', method: 'post', data,plaintext:true });

/**
 * 提交申请
 */
 export const submitApply = (data = {}) => encryptRequest({url:'/interface/hqd/submit-apply', method:'post', data});

 /**
 * 查询我的虹企贷申请
 */
export const getCustomerApply = (data = {}) => encryptRequest({ url: '/interface/hqd/customer-apply', method: 'post', data, plaintext:true});


/**
 * 申请2023虹企贷
 */
export const sendFutureApply = (data = {}) => encryptRequest({url:'/interface/hqd/needs',method:'post',data})


/**
 * 获取虹企贷指定银行
 */
export const appointBankStr = (data = {}) => axiosInstance.get('/interface/hqd/appointBankStr')

/**
 * 获取虹企贷维护表单名
 */
export const getHQDFormTitle = (data = {}) => axiosInstance.get('/interface/hqd/XXX')


/**
 * 获取虹企贷维护表单名
 */
export const getHQDNeed = (data = {}) => axiosInstance.get('/interface/hqd/needs/in-progress')
