<template>
  <div id="app">
    <uni-header></uni-header>
    <router-view />
  </div>
</template>

<script>
import uniHeader from "@/components/uni-header.vue"
export default {
  name: "App",
  components: {
    uniHeader,
  },
  created () {
    if (this.$store.state.isLogin) {
      this.$api.login.checkLogin().catch((error) => {
        this.$store.commit("logout")
      })
    }
  },
};
</script>

<style scoped></style>
