/* track-events.js */
import { reportTrackPv, reportTrackUv } from '@/services/report-track'
let login = false
const trackEvents = {
  /**
   * @name UVPV 固定名称不支持修改
   * @desc UV、PV埋点
   * @param {Object} context 当前上下文
   */
  UVPV (context) {
    const pageEnterTime = context.$data.PAGE_ENTER_TIME
    let historyTime = localStorage.getItem('front-page-enter-time')
    localStorage.setItem('front-page-enter-time', pageEnterTime)
    if (historyTime == null) {
      historyTime = pageEnterTime
    }
    const timeDifference = Math.abs(pageEnterTime - historyTime)
    if (timeDifference > 100) {
      const userId = this.loadUserId(context)
      this.recordPv(userId, context)
    }
  },
  /**
   * @name TONP 固定名称不支持修改
   * @desc 页面停留时间埋点（Time on Page）
   * @param {Object} context 当前上下文
   * @param {Timestamp} et 进入页面时间
   * @param {Timestamp} dt 离开页面时间
   */
  TONP (context, { et, dt }) {
    const time = `${(dt - et) / 1e3}s`
    console.log('页面停留时间：' + time)
  },
  recordPv (userId, context) {
    const page = context.$route.path
    const tokenKey = 'login_info_token'
    let token = localStorage.getItem(tokenKey)
    reportTrackPv({
      autoGenerateUserId: userId,
      pagePath: page,
      type: token ? '2' : '1'
    }).then(r => { })
  },
  loadUserId (context) {
    const userIdKey = 'hk-user-id'
    const expireDateKey = 'hk-user-id-expire-date'
    const tokenKey = 'login_info_token'
    let token = localStorage.getItem(tokenKey)
    let userId = localStorage.getItem(userIdKey)
    let expireDate = localStorage.getItem(expireDateKey)
    const now = new Date()
    const path = context.$route.path
    if (!userId || !expireDate || now.getTime() > Number(expireDate) || login) {
      // 如果不存在或已过期，生成一个新的userId和失效时间
      if (!login) {
        userId = this.generateUUID()
        const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1)
        expireDate = tomorrow.getTime()
        // 将新的userId和失效时间存储到localStorage
        localStorage.setItem(userIdKey, userId)
        localStorage.setItem(expireDateKey, expireDate)
      }
      login = false
      reportTrackUv({
        autoGenerateUserId: userId,
        pagePath: path,
        type: token ? '2' : '1'
      }).then(r => { })
    }
    return userId
  },
  18018: ({ rest, $route }) => {
    if (rest.token) {
      login = true
      console.log(trackEvents)
      trackEvents.loadUserId({ $route })
    }
  },
  generateUUID () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0
      const v = c === 'x' ? r : (r & 0x3 | 0x8)
      return v.toString(16)
    })
  }

}
export default trackEvents