<template>
  <div
    :class="[
      'header-nav',
      $route.meta.top && rect?.top === 0 ? 'index-header' : '',
    ]"
    :style="{
      position: $route.meta.top ? 'fixed' : 'relative',
      left: $route.meta.top ? rect?.left + 'px' : '0',
      background: $route.meta.headerBgColor,
      boxShadow:
        $route.meta.top && rect?.top === 0
          ? 'none'
          : $route.meta.headerBgColor
          ? 'none'
          : '0 0 16px 0 rgba(0, 0, 0, 0.08)',
    }"
  >
    <header class="flex">
      <div class="left item-center">
        <div class="logo">
          <router-link to="/">
            <img
              class="logo-img-1"
              src="@/assets/images/logo-hongkou-1.png"
              alt="logo"
              v-show="$route.meta.top && rect?.top === 0"
            />
            <img
              class="logo-img"
              src="@/assets/images/logo_hongkou.png"
              alt="logo"
              v-show="!($route.meta.top && rect?.top === 0)"
            />
          </router-link>
        </div>
        <div class="area_info">
          <div class="acitve_area">
            <i class="icons icon_location"></i>
            <span>虹口区</span>
            <i class="icon menu_icon"></i>
          </div>
          <div class="area_sub">
            <i class="up_arrow"></i>
            <div>
              <p>
                <span>市级：</span
                ><a href="https://shcreditloan.org.cn">上海市</a>
              </p>
              <p><span>区级：</span></p>
            </div>
            <ul>
              <li v-for="item in areaList" :key="item.text">
                <a
                  :href="item.link"
                  :class="{ disabled: !item.link, active: item.active }"
                  @click.prevent="linkClick(item.link, item.active)"
                  >{{ item.text }}</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="right flex">
        <ul class="nav-ul">
          <li :class="{ active: $route.path === '/home' }">
            <router-link to="/home">首页</router-link>
          </li>
          <li
            :class="{
              active: ['/detail', '/financing-list'].includes($route.path),
            }"
          >
            <router-link to="/financing-list">金融产品</router-link>
          </li>
          <li :class="{ active: $route.path === '/demand-release' }">
            <router-link to="/demand-release">需求发布</router-link>
          </li>
          <li :class="{ active: $route.path === '/financing-news' }">
            <router-link to="/financing-news">融资资讯</router-link>
          </li>
          <li
            :class="{
              active: ['/anti-epidemic-area', '/hongqidai'].includes(
                $route.path
              ),
            }"
          >
            <a @click.stop="() => {}">特色专区 </a>

            <div class="secondary_menu">
              <i class="up_arrow"></i>
              <div
                class="secondary_menu_nav"
                :class="{ active: $route.path === '/feature/hk-area' }"
              >
                <router-link to="/feature/hk-area">虹口秒贷</router-link>
              </div>
              <div
                class="secondary_menu_nav"
                :class="{ active: $route.path === '/anti-epidemic-area' }"
              >
                <router-link to="/anti-epidemic-area"
                  >高质量发展贴息补助专区</router-link
                >
              </div>
              <div
                class="secondary_menu_nav"
                :class="{ active: $route.path === '/hongqidai' }"
              >
                <router-link to="/hongqidai">虹企贷专区</router-link>
              </div>
            </div>
          </li>
          <li :class="{ active: $route.path === '/about' }">
            <router-link to="/about">虹口介绍</router-link>
          </li>
        </ul>
        <div class="login-nav">
          <span v-if="!isLogin">
            <a @click.prevent="showLogin">用户登录</a
            ><template>&nbsp;&nbsp;|&nbsp;&nbsp;</template
            ><router-link to="/user/regist">注册</router-link>
          </span>
          <span v-else
            ><router-link to="/user-center">用户中心</router-link
            ><template>&nbsp;&nbsp;|&nbsp;&nbsp;</template
            ><a id="logout" @click="logout" style="cursor: pointer">退出</a>
          </span>
        </div>
      </div>
    </header>
    <div
      class="modal fade"
      id="constructionModal"
      role="dialog"
      aria-labelledby="constructionModalLabel"
      data-backdrop="false"
    >
      <div class="modal-dialog construction_dialog" role="document">
        <div class="modal-content">
          <div class="modal-body construction">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="text-center">
              <i class="icon_warning"></i><span>站点建设中...</span>
            </h4>
          </div>
        </div>
      </div>
    </div>

    <!-- Login -->
    <div
      class="modal fade"
      id="loginModal"
      role="dialog"
      aria-labelledby="loginModalLabel"
      data-backdrop="false"
    >
      <div class="modal-dialog login_dialog" role="document">
        <div class="modal-content">
          <div class="modal-body login">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="login_title">快速登录</h4>
            <div class="input-group" style="margin-top: 20px">
              <i class="icon icon_phone"></i>
              <input
                type="text"
                id="username"
                v-model="phoneNum"
                class="form-control"
                aria-label="..."
                placeholder="手机号"
                style="border-radius: 4px 4px 0 0"
              />
            </div>
            <div class="input-group">
              <i class="icon icon_pic"></i>
              <input
                type="text"
                id="verify_code"
                v-model="code"
                class="form-control"
                maxlength="6"
                aria-label="..."
                placeholder="验证码"
                style="border-radius: 0 0 4px 4px"
              />
              <img
                @click="getPicVerify"
                class="verify_code"
                :src="verifyCode"
                width="70"
                height="27"
                alt=""
              />
            </div>
            <div class="input-group">
              <i class="icon icon_sms"></i>
              <input
                type="text"
                id="sms"
                v-model="messageCode"
                class="form-control"
                aria-label="..."
                placeholder="短信验证码"
              />
              <span class="sms_code" @click="getShortMesCode">{{
                countDown > 0 ? countDown : "获取验证码"
              }}</span>
            </div>
            <button
              class="login_btn"
              v-track:18018.click.async="{ login, rest }"
            >
              登录
            </button>
            <p>
              如未注册账号，则可
              <router-link to="/user/regist">快速注册</router-link>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- not_registe -->
    <div
      class="modal fade"
      id="notRegisteModal"
      role="dialog"
      aria-labelledby="notRegisteModalLabel"
      data-backdrop="false"
    >
      <div class="modal-dialog not_registe_dialog" role="document">
        <div class="modal-content">
          <div class="modal-body not_registe">
            <h4 class="text-center">
              <i class="icon_warning"></i><span>您当前尚未注册</span>
            </h4>
            <p class="count_down">
              <span>3</span> 秒后系统将为您跳转至注册页面
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/utils/event-bus.js"
import { encryptDataFn } from '@/utils/encrypt'
export default {
  name: "uniHeader",
  data () {
    return {
      rect: null,
      areaList: [
        {
          text: "静安区",
          link: "https://shcreditloan.org.cn/xyd/sub/jingan-index.html",
          active: false,
        },
        {
          text: "普陀区",
          link: "https://xyd.shpt.gov.cn/#/home",
          active: false,
        },
        {
          text: "长宁区",
          link: "https://shcreditloan.org.cn/xyd/sub/changning-index.html",
          active: false,
        },
        {
          text: "浦东新区",
          link: "https://www.pudong.gov.cn/025020/index.html",
          active: false,
        },
        {
          text: "嘉定区",
          link: "http://www.jiading.gov.cn/xinyong/xinyd",
          active: false,
        },
        {
          text: "徐汇区",
          link: "https://shcreditloan.org.cn/xyd/sub/xuhui-index.html",
          active: false,
        },
        {
          text: "黄浦区",
          link: "https://xydhp.shhuangpu.gov.cn/app/home",
          active: false,
        },
        {
          text: "松江区",
          link: "https://shcreditloan.org.cn/xyd/sub/songjiang-index.html",
          active: false,
        },
        {
          text: "青浦区",
          link: "https://shcreditloan.org.cn/xyd/sub/qingpu-index.html",
          active: false,
        },
        { text: "杨浦区", link: "https://xyd.shyp.gov.cn/#/home", active: false },
        { text: "虹口区", link: "https://hkcreditloan.shhk.gov.cn/#/home", active: true },
        { text: "宝山区", link: "https://www.shbsq.gov.cn/xybs/xyd/front/#/home", active: false },
        { text: "闵行区", link: "http://www.shmh.gov.cn/xymh/home/xyd/index.html", active: false },
        { text: "金山区", link: "https://ywtb.jinshanqu.net/ywcr", active: false },
        { text: "奉贤区", link: "https://www.fengxian.gov.cn/credit/fx_xyd/", active: false },
        { text: "崇明区", link: "http://xyy.shcm.gov.cn/index.html", active: false },
      ],
      verifyCode: "",
      randomCode: null,
      imgCodeKey: null,
      phoneNum: "",
      code: "",
      messageCode: "",
      countDown: 0,
      callback: null,
      rest: null
    }
  },
  computed: {
    isLogin () {
      return this.$store.state.isLogin
    },
  },
  created () {
    this.getPicVerify()
    EventBus.$on("showLoginModal", this.showLogin)
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy () {
  },
  watch: {
    '$route.path' () {
      this.onScroll()
    }
  },
  methods: {
    onScroll () {
      this.rect = null
      if (!this.$route.meta.top) return
      this.rect = document.querySelector('#app').getClientRects()[0]
    },
    getPicVerify () {
      this.$api.login.getPicVerify().then((data) => {
        console.log(data)
        const { verifyCode, randomCode, imgCodeKey } = data
        this.verifyCode = verifyCode
        this.randomCode = randomCode
        this.imgCodeKey = imgCodeKey
      })
    },
    linkClick (link, active = false) {
      if (active) {
        return
      }
      if (link) {
        window.location.href = link
      } else {
        $("#constructionModal").modal("show")
      }
    },
    showLogin ({ callback } = {}) {
      console.log('触发showLogin')
      if (callback) this.callback = callback
      $("#loginModal").modal("show")
    },
    /**
     * 获取短信验证码
     */
    getShortMesCode () {
      if (this.countDown > 0) {
        return
      }
      if (this.code != this.randomCode) {
        alert("图形验证码错误")
        return
      }

      if (this.phoneNum === "") {
        alert("请输入手机号码")
        return
      }
      if (!this.$util.isPhone(this.phoneNum)) {
        alert("请输入正确的手机号码")
        return
      }
      this.startCountDown()
      this.$api.login
        .sendSms({
          imgCodeKey: this.imgCodeKey,
          randomCode: this.randomCode,
          phoneNum: this.phoneNum,
          type: "1",
        }).catch(error => {
          window.clearInterval(this.timer)
          this.countDown = 0
          if (error.resultCode === '1003') {
            this.$alert('图形验证码已失效', '提示', {
              confirmButtonText: '确定',
              callback: () => {
                this.getPicVerify()
              }
            })
          }
        })
    },
    startCountDown () {
      this.countDown = 60
      this.timer = setInterval(() => {
        this.countDown--
        if (this.countDown <= 0) {
          clearInterval(this.timer)
        }
      }, 1000)
    },
    async login () {
      const response = await new Promise(resolve => {
        if (this.code != this.randomCode) {
          alert("图形验证码错误")
          return
        }

        if (this.phoneNum === "") {
          alert("请输入手机号码")
          return
        }
        if (!this.$util.isPhone(this.phoneNum)) {
          alert("请输入正确的手机号码")
          return
        }
        if (!this.messageCode) {
          alert("请输入短信验证码")
          return
        }
        console.log({
          imgCodeKey: this.imgCodeKey,
          randomCode: this.randomCode,
          phone: this.phoneNum,
          messageCode: this.messageCode,
        })
        this.$api.login
          .login({
            imgCodeKey: this.imgCodeKey,
            randomCode: this.randomCode,
            phone: this.phoneNum,
            messageCode: this.messageCode,
          })
          .then((data) => {
            console.log("login::", data)
            const { token } = data
            this.$store.commit("login", { token, userName: this.phoneNum })
            $("#loginModal").modal("hide")
            if (this.callback) {
              this.callback()
              this.callback = null
            }
            resolve(data)
          })
          .catch((error) => {
            console.log("login.errror::", error)
            const { resultCode = null, resultDesc = "", data = {} } = error
            if (resultCode === "1007" || resultCode === "1014") {
              $("#loginModal").modal("hide")
              this.showNotRegiste()
            } else if (resultCode === "0001") {
              $("#loginModal").modal("hide")
              const enDate = encryptDataFn(data.data)
              const { token } = enDate
              this.$store.commit("login", { token: token, userName: this.phoneNum })
              alert(resultDesc)
              if (this.pushPath) {
                this.$router.push({ path: this.pushPath })
                this.pushPath = null
              }
              resolve(enDate)
            } else {
              alert(resultDesc)
            }
          })
      })
      this.rest = response
    },
    showNotRegiste () {
      $("#notRegisteModal").modal({
        backdrop: "static",
        keyboard: false,
        show: true,
      })
      setTimeout(() => {
        $("#notRegisteModal").hide()
        this.$router.push(`/user/regist?loginName=${this.phoneNum}`)
      }, 3000)
    },
    logout () {
      this.$api.login.signOut().then((data) => {
        this.$store.commit("logout")
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.header-nav {
  position: relative;
  z-index: 2;
  width: 100%;
  background-color: #ffffff;
  min-width: 1200px;
  top: 0;
  left: 0;
  header {
    width: 1200px;
    height: 84px;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
  }
  .logo img {
    width: 280px;
    height: 40px;
  }
  .menu_icon {
    height: 18px;
    font-size: 18px;
    padding-right: 18px;
    line-height: 18px;
    background: url("@/assets/images/icon_10.png") -52px -20px no-repeat;
  }
  .area_info {
    position: relative;
    margin-left: 20px;
    cursor: pointer;
    padding: 10px 0;
  }
  .area_info .acitve_area {
    width: 100px;
    height: 36px;
    line-height: 36px;
    border: 1px solid rgba(216, 216, 216, 0.5);
    text-align: center;
    color: #888888;
  }
  .area_info:hover .menu_icon {
    background: url("@/assets/images/icon_10.png") -52px 5px no-repeat;
  }
  .nav-ul {
    display: flex;
    align-items: center;
  }
  .nav-ul > li {
    cursor: pointer;
    padding: 10px 0;
    border-bottom: 2px solid transparent;
    position: relative;
    &:hover .secondary_menu {
      display: block;
    }
  }
  .nav-ul > li:not(:first-child) {
    margin-left: 32px;
  }
  .nav-ul > li:hover,
  .nav-ul > li.active {
    border-color: #2383ea;
  }

  .nav-ul > li:hover > a,
  .nav-ul > li.active > a {
    color: #2383ea;
    font-weight: bold;
  }
  .nav-ul > li a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    color: #222222;
    color: #222222;
  }
  .login-nav {
    padding: 10px 0 10px 32px;
    line-height: normal;
  }
  .login-nav a {
    color: #222222;
    font-size: 14px;
    cursor: pointer;
    font-weight: 500;
  }
  .area_info:hover .area_sub {
    display: block;
  }
  &.index-header {
    background-color: transparent;
    position: fixed;
    .nav-ul > li {
      & > a {
        opacity: 1;
        color: #222;
      }
      &:hover,
      &.active {
        opacity: 1;
        border-color: #2383ea;
      }
    }
    .login-nav {
      span,
      a {
        opacity: 1;
        color: #222;
      }
    }
    .acitve_area {
      color: #888888;
      .menu_icon {
        background-position: -52px -20px;
      }
      &:hover .menu_icon {
        background-position: -52px 6px;
      }
    }
    .nav-ul > li {
      & > a {
        color: #fff;
        opacity: 0.8;
      }
      &:hover,
      &.active {
        opacity: 1;
        border-color: #fff;
      }
    }
    .login-nav {
      span,
      a {
        color: #fff;
        opacity: 0.8;
      }
    }
    .acitve_area {
      color: #fff;

      .menu_icon {
        background-position: -5px -20px;
      }
      &:hover .menu_icon {
        background-position: -5px 6px;
      }
    }
  }
}

.area_sub {
  display: none;
  position: absolute;
  z-index: 20;
  top: 60px;
  padding: 25px;
  width: 260px;
  height: 260px;
  font-size: 14px;
  font-weight: 400;
  color: #888888;
  line-height: 21px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 6px 24px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
}
.area_sub ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.area_sub ul > li {
  min-width: 30%;
  text-align: left;
}
.area_sub a {
  color: #222222;
  line-height: 26px;
  text-decoration: none;
}
.area_sub a.disabled {
  color: #888888;
}
.area_sub a.active,
.area_sub a:hover {
  color: #e83630;
}
.area_sub ul > li:nth-child(3n) {
  text-align: right;
}
.area_sub ul > li:nth-child(3n + 2) {
  text-align: center;
}
.verify_code {
  cursor: pointer;
}
.secondary_menu {
  display: none;
  position: absolute;
  z-index: 20;
  top: 60px;
  left: 0;
  padding: 25px;
  width: max-content;
  font-size: 14px;
  font-weight: 400;
  color: #888888;
  line-height: 21px;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 6px 24px 4px rgba(0, 0, 0, 0.1);
  .up_arrow {
    left: 10px;
  }
  .secondary_menu_nav {
    a {
      font-size: 14px;
      color: #222222;
    }

    &.active a {
      color: #2383ea;
      font-weight: bold;
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
    &:hover > a {
      font-weight: bold;
      color: #2383ea;
    }
  }
}
</style>
