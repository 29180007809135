import { encryptRequest } from "@/utils/encrypt"
import axiosInstance from "../axios"

/**
 * 获取个人登录信息
 */
export const getUserInformation = () => encryptRequest({ url: '/interface/customer/information', method: 'post' })

/**
 * 获取用户详细信息以及认证企业集合
 */
export const getUserEnterprise = () => encryptRequest({ url: '/interface/hqd/customer-info-enterprise', method: 'post' })


/**
 * 获取用户所有企业
 */
export const getCompanyList = () => axiosInstance.post('/interface/customer/select-companies')


/**
 * 获取用户认证企业
 */
export const getAuthCompanyList = () => axiosInstance.get('/interface/customer/company')

/**
 * 取消用户认证企业
 */
export const cancelAuthCompany = (id) => axiosInstance.post(`/interface/customer/company/cancel/${id}`)

/**
 * 获取认证企业地址跳转
 */
export const getAuthCompanyAddress = (data) => axiosInstance.post('/interface/authorize/toPage', data)
