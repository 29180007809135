import axios from "axios";
import store from "../store/store";

const axiosInstance = axios.create({
	baseURL: process.env.VUE_APP_BASE_API
})

/**
 * 添加请求拦截器
 */
axiosInstance.interceptors.request.use(
    (config) => {
        config.headers.token = store.state.token;
        return config;
    },
    (error) => {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);
/**
 * 添加响应拦截器
 */
axiosInstance.interceptors.response.use(
    (response) => {
        // 对响应数据做点什么
        if (response.status == 200) {
            const { resultDesc, resultCode = null, data = {} } = response.data;
            if (resultCode === "0000") {
                return data;
            }
            if (resultCode === "1007" || resultCode === "1014" || resultCode === "0001") {
                return Promise.reject(response.data);;
            }
          if (resultCode === "1008") {//登录超时
                store.commit("logout");
                alert(resultDesc);
                window.location.href = "/";
                return Promise.reject(response.data);
            }
            // alert(resultDesc)
            return Promise.reject(response.data);
        }
        return Promise.reject(error);
    },
    (error) => {
        // 对响应错误做点什么
        return Promise.reject(error);
    }
);

export default axiosInstance;