import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";
import Api from "@/services/api";
Vue.use(Vuex);

const LOGIN_INFO_TOKEN_KEY = "login_info_token";
const token = localStorage.getItem(LOGIN_INFO_TOKEN_KEY);
const isLogin = token != null;
const defalutState = {
  customerName: "",
  phoneNum: "",
  customerIdNumber: "",
  isLogin,
  token,
  companiesList: [], //已认证企业列表
};
const store = new Vuex.Store({
  plugins: [createLogger()],
  state: {
    ...defalutState,
  },
  mutations: {
    login(state, pyload) {
      console.log("store.login::", pyload);
      const { userName, token } = pyload;
      state.isLogin = true;
      state.userName = userName;
      state.token = token;
      localStorage.setItem(LOGIN_INFO_TOKEN_KEY, token);
    },
    logout(state) {
      state.isLogin = false;
      state.userName = null;
      state.token = null;
      localStorage.removeItem(LOGIN_INFO_TOKEN_KEY);
    },
    setUserEnterpriseInfo(state, pyload) {
      const {
        customerName,
        phoneNum,
        customerIdNumber,
        companiesList = [],
      } = pyload;
      state.customerName = customerName;
      state.phoneNum = phoneNum;
      state.companiesList = companiesList;
      state.customerIdNumber = customerIdNumber;
    },
  },
  actions: {
    getUserEnterprise({ commit }) {
      Api.userCenter.getUserEnterprise().then((data) => {
        
        console.log("getUserEnterprise::", data);
        commit("setUserEnterpriseInfo", data);
      });
    },
  },
});
export default store;
