import * as login from "./login";
import * as userCenter from "./user-center";
import * as hongqidai from "./hongqidai";
import * as financingNews from "./financing-news";
import * as demandRelease from "./demand-release";

export default {
  login,
  userCenter,
  hongqidai,
  financingNews,
  demandRelease,
};
