export const Util = {
  isPhone(phone) {
    const regex = /^(?:(?:\+|00)86)?1\d{10}$/;
    return regex.test(phone);
  },
  formatTime(str) {
    let n = parseInt(str);
    let D = new Date(n);
    let year = D.getFullYear(); //四位数年份

    let month = D.getMonth() + 1; //月份(0-11),0为一月份
    month = month < 10 ? "0" + month : month;

    let day = D.getDate(); //月的某一天(1-31)
    day = day < 10 ? "0" + day : day;

    let hours = D.getHours(); //小时(0-23)
    hours = hours < 10 ? "0" + hours : hours;

    let minutes = D.getMinutes(); //分钟(0-59)
    minutes = minutes < 10 ? "0" + minutes : minutes;

    let seconds = D.getSeconds(); //秒(0-59)
    seconds = seconds < 10 ? "0" + seconds : seconds;
    // var week = D.getDay();//周几(0-6),0为周日
    // var weekArr = ['周日','周一','周二','周三','周四','周五','周六'];

    let now_time = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return now_time;
  },
  formatTime2minutes(str) {
    let n = parseInt(str);
    let D = new Date(n);
    let year = D.getFullYear(); //四位数年份

    let month = D.getMonth() + 1; //月份(0-11),0为一月份
    month = month < 10 ? "0" + month : month;

    let day = D.getDate(); //月的某一天(1-31)
    day = day < 10 ? "0" + day : day;

    let hours = D.getHours(); //小时(0-23)
    hours = hours < 10 ? "0" + hours : hours;

    let seconds = D.getSeconds(); //秒(0-59)
    seconds = seconds < 10 ? "0" + seconds : seconds;

    let minutes = D.getMinutes(); //分钟(0-59)
    minutes = minutes < 10 ? "0" + minutes : minutes;

    let now_time = `${year}-${month}-${day} ${hours}:${minutes}`;
    return now_time;
  },
  formatTime2Year(str) {
    let n = parseInt(str);
    let D = new Date(n);
    let year = D.getFullYear(); //四位数年份
    return year;
  },
  formatTime2month(str) {
    let n = parseInt(str);
    let D = new Date(n);
    let month = D.getMonth() + 1; //月份(0-11),0为一月份
    month = month < 10 ? "0" + month : month;
    return month;
  },
  formatTime2day(str) {
    let n = parseInt(str);
    let D = new Date(n);
    let day = D.getDate(); //月的某一天(1-31)
    day = day < 10 ? "0" + day : day;
    return day;
  },
  fixMoney(num){
    let str = num.toString();
    let reg = str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
    return str.replace(reg,"$1,");
  }
};
